.Buttons {
  margin-top: -16px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  &.spread {
    justify-content: space-between;
  }

  .Button {
    margin-top: 16px;
  }

  .Button + .Button {
    margin-left: 16px;
  }
}

* + .Buttons {
  margin-top: 16px;
}
