@import '../../variables';

.Loading {
  display: inline-block;
  transform: translate3d(0, 0, 1px);

  > div {
    display: block;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background-color: #fff;
    animation: loading 3.75s $easeInOutSine infinite;
  }

  &.medium > div {
    width: 24px;
    height: 24px;
  }

  &.small > div {
    width: 16px;
    height: 16px;
  }

  &.primary > div {
    background-color: $color-primary-dark;
  }

  &.success > div {
    background-color: $color-success-dark;
  }

  &.warning > div {
    background-color: $color-warning-dark;
  }

  &.danger > div {
    background-color: $color-error-dark;
  }
}

.light .Loading {
  > div {
    background-color: #000;
  }

  &.primary > div {
    background-color: $color-primary-light;
  }

  &.success > div {
    background-color: $color-success-light;
  }

  &.warning > div {
    background-color: $color-warning-light;
  }

  &.danger > div {
    background-color: $color-error-light;
  }
}

@keyframes loading {
  0%,
  100% {
    animation-timing-function: $easeInOutSine;
  }
  0% {
    transform: rotateY(0deg);
  }
  50% {
    transform: rotateY(1800deg);
    animation-timing-function: $easeInOutSine;
  }
  100% {
    transform: rotateY(3600deg);
  }
}

.Loading + .Loading {
  margin-left: 12px;
}

/*
.lds-circle > div {
  animation: lds-circle 2.4s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}
@keyframes lds-circle {
  0%, 100% {
    animation-timing-function: cubic-bezier(0.5, 0, 1, 0.5);
  }
  0% {
    transform: rotateY(0deg);
  }
  50% {
    transform: rotateY(1800deg);
    animation-timing-function: cubic-bezier(0, 0.5, 0.5, 1);
  }
  100% {
    transform: rotateY(3600deg);
  }
}

*/
