@import '../../variables';

.Content {
  width: 90vw;
  max-width: $max-width-text + 2 * $spacing-medium-desktop;
  margin-left: auto;
  margin-right: auto;

  @include ss(
    'padding-top',
    $spacing-large-mobile + $menu-height-mobile,
    $spacing-large-desktop + $menu-height-desktop
  );

  @include ss('padding-bottom', $spacing-large-mobile, $spacing-large-desktop);
}
